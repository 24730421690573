import React from 'react';
import styled, { css } from 'styled-components';
import Glide from '@glidejs/glide';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import theme from 'styled-theming';

import { hoverAndActiveColor } from 'helpers/themehelpers';
import { colors } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';
import { layerable } from 'helpers/traits';

import Icon from './icon';

/** Theming */
const iconMobileColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.secondary,
});

/** Ghost-Arrows */
const ghostArrows = css`
    &:hover,
    & {
        background-color: transparent;
        color: ${hoverAndActiveColor};
        border: 2px solid ${hoverAndActiveColor};
        box-shadow: none;
    }

    &:hover {
        background-color: ${hoverAndActiveColor};
        color: ${colors.white};
    }
`;

/** Wrapper */
const Wrapper = styled.div`
    max-width: ${rem(1230)};
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.5s;

    ${({ visible }) => visible && `opacity: 1`};

    .glide__slides {
        margin: 0 auto;
    }

    .glide__slide {
        ${({ hero }) =>
            !hero &&
            `
            padding-bottom: 20px;
            height: auto;

            > * {
                height: 100%;
            }
        `};
    }

    /** Glide-Arrows **/
    .glide__arrow {
        align-items: center;
        background-color: ${colors.white};
        color: ${iconMobileColor};
        border-radius: 50%;
        border: none;
        box-shadow: 0 0 ${rem(2)} ${rem(2)} ${rgba(colors.black, 0.2)};
        display: flex;
        height: ${rem(45)};
        justify-content: center;
        width: ${rem(45)};
        z-index: 1;

        &.glide__arrow--left {
            left: ${({ hero }) => (!hero ? `${rem(-10)}` : `${rem(10)}`)};

            ${mq.medium`
                left: ${rem(15)};
            `};
        }

        &.glide__arrow--right {
            right: ${({ hero }) => (!hero ? `${rem(-10)}` : `${rem(10)}`)};

            ${mq.medium`
                right: ${rem(15)};
            `};
        }

        ${mq.xxlarge`
            transition: color 0.25s, color 0.25s;

            ${({ hero }) =>
                !hero &&
                css`
                    ${ghostArrows};

                    &.glide__arrow--left {
                        left: ${rem(-30)};
                        transform: translate(-100%, -50%);
                    }
                    &.glide__arrow--right {
                        right: ${rem(-30)};
                        transform: translate(100%, -50%);
                    }
                `};
        `};
    }

    ${({ hero }) =>
        /*
            Styles, wenn der Slider im Hero-Modus ist (also, wenn der Slider
            in der Homestage eingebaut ist. Styling exklusiv darauf bezogen.
        */
        hero &&
        css`
            .glide__track {
                position: relative;

                mask-image: linear-gradient(
                    to right,
                    ${rgba(colors.white, 0)} 0%,
                    ${rgba(colors.white, 1)} 10%,
                    ${rgba(colors.white, 1)} 90%,
                    ${rgba(colors.white, 0)} 100%
                );

                ${mq.medium`
                    mask-image: linear-gradient(
                        to right,
                        ${rgba(colors.white, 0)} 0%,
                        ${rgba(colors.white, 1)} 5%,
                        ${rgba(colors.white, 1)} 95%,
                        ${rgba(colors.white, 0)} 100%
                    );
                `};

                ${mq.large`
                    mask-image: linear-gradient(
                        to right,
                        ${rgba(colors.white, 1)} 0%,
                        ${rgba(colors.white, 1)} 80%,
                        ${rgba(colors.white, 0)} 100%
                    );
                `};
            }

            /** Box-Shadow für Einzelne Slides */
            .glide__slide {
                ${layerable(1)};
            }

            /** Abstand von Buttons / Bullets zum Rand rechts */
            .glide__bullets,
            .glide__arrows {
                ${mq.large`
                    margin-right: ${rem(30)};
                `};

                ${mq.xxlarge`
                    margin-right: ${rem(45)};
                `};

                ${mq.xxxlarge`
                    margin-right: ${rem(60)};
                `};
            }

            /** Bullets - Pagination */
            .glide__bullets {
                display: none;
                ${mq.xlarge`
                    display: flex;
                    position: relative;
                    justify-content: flex-end;
                    margin-top: 1em;
                    right: auto;
                    left: auto;
                    bottom: auto;
                    transform: translate(0, 0);
                `};
            }

            .glide__bullet {
                ${mq.large`
                        border: none;
                        box-shadow: 0 0 ${rem(2)} ${rem(2)} ${rgba(colors.black, 0.15)};
                        background-color: ${rgba(colors.white, 0.45)};
                        height: ${rem(14)};
                        transition: background-color 0.25s;
                        width: ${rem(14)};

                        &:hover,
                        &:focus,
                        &:active,
                        &.glide__bullet--active,
                        &.glide__bullet--active:hover {
                            border: none;
                            background-color: ${colors.white};
                        }
                    `};
            }

            .glide__arrows {
                ${mq.xlarge`
                    margin-bottom: 1em;
                `};
            }

            .glide__arrows {
                ${mq.xlarge`
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    right: 0;
                    top: 0;
                `};
            }

            .glide__arrow {
                ${mq.xlarge`
                    ${ghostArrows};

                    &.glide__arrow--left,
                    &.glide__arrow--right {
                        position: relative;
                        left: auto;
                        top: auto;
                        right: auto;
                        bottom: auto;
                        transform: translate(0, 0);
                    }
                    &.glide__arrow--left {
                        margin-right: ${rem(10)};
                    }
                `};
            }
        `};
`;

/**
 * Slider Komponente
 */
class Slider extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        hero: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        hero: false,
        className: '',
    };

    glideRef = React.createRef();

    state = {
        glideMounted: false,
    };

    /**
     * Mounted
     */
    componentDidMount() {
        const { hero, children } = this.props;

        // Glide mit Settings initialisieren
        const glide = new Glide(this.glideRef.current, {
            type: children.length >= 4 || hero ? 'carousel' : 'slider',
            perView: hero ? 3 : 4,
            gap: 25,
            focusAt: 0,
            peek: hero
                ? {
                      before: 0,
                      after: 150,
                  }
                : 0,
            breakpoints: hero
                ? {
                      415: {
                          gap: 10,
                          peek: 50,
                          perView: 1,
                          focusAt: 'center',
                      },
                      600: {
                          gap: 15,
                          peek: 20,
                          perView: 2,
                          focusAt: 'center',
                      },
                      900: {
                          gap: 20,
                          peek: 0,
                          perView: 3,
                          focusAt: 0,
                      },
                      1200: {
                          gap: 25,
                          peek: 0,
                          perView: 3,
                          focusAt: 0,
                          peekAt: {
                              before: 0,
                              after: 150,
                          },
                      },
                  }
                : {
                      600: {
                          gap: 12,
                          peek: 20,
                          perView: 1,
                      },
                      1024: {
                          gap: 20,
                          peek: 30,
                          perView: 2,
                      },
                      1200: {
                          gap: 20,
                          peek: 50,
                          perView: 3,
                      },
                      1440: {
                          gap: 25,
                          peek: 60,
                          perView: 3,
                      },
                  },
        });

        glide.on('mount.after', () => {
            this.setState({
                glideMounted: true,
            });
        });

        glide.mount();
    }

    /**
     * Render
     * @param {string/node} props.children Die Struktur der Navigation
     * @param {boolean} props.hero Flag, ob Hero-Mode oder nicht (Befindet sich in der Homestage)
     */
    render() {
        const { className, children, hero } = this.props;
        const { glideMounted } = this.state;
        const items = children.map(item => (
            <li className="glide__slide" key={item.key}>
                {item}
            </li>
        ));

        return (
            <Wrapper
                className={`glide ${className}`}
                ref={this.glideRef}
                hero={hero}
                visible={glideMounted}
            >
                <div className="glide__arrows" data-glide-el="controls">
                    <button
                        type="button"
                        className="glide__arrow glide__arrow--left"
                        data-glide-dir="<"
                        aria-label="Vorherigen Slide anzeigen"
                    >
                        <Icon type="arrowLeft" />
                    </button>
                    <button
                        type="button"
                        className="glide__arrow glide__arrow--right"
                        data-glide-dir=">"
                        aria-label="Nächsten Slide anzeigen"
                    >
                        <Icon type="arrowRight" />
                    </button>
                </div>
                <div data-glide-el="track" className="glide__track">
                    <ul className="glide__slides">{items}</ul>
                </div>
                {hero && (
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {children.map((item, i) => (
                            <button
                                type="button"
                                className="glide__bullet"
                                key={`bullet-${item.key}`}
                                data-glide-dir={`=${i}`}
                            />
                        ))}
                    </div>
                )}
            </Wrapper>
        );
    }
}

export default Slider;
